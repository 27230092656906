<template>
  <section class="box-height" style="margin-bottom: 70px">
    <div class="box-title" style="border: 1px #e5e5e5 solid">
      <el-row class="tip">
        <el-form @submit.native.prevent :model="filter" ref="filter">
          <el-row :gutter="24">
            <el-col :span="10" style="padding-right: 8px">
              <el-form-item label prop="name" style="margin-bottom: 10px">
                <el-input v-model="filter.name" placeholder="请输入公司名称">
                  <i
                    slot="append"
                    @click="submitForm('filter')"
                    class="el-icon-search"
                  ></i>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="2" style="margin-top: 7px; padding-left: 0">
              <span class="subIco">
                <el-tooltip
                  placement="bottom-start"
                  effect="dark"
                  popper-class="showTip"
                >
                  <div slot="content">
                    <div class="tipTitle">说明</div>
                    <div class="tipContent">
                      系统内添加的公司需要天眼查核查，部分公司<br />天眼查无法核查，特殊公司模块就是完善无法<br />核查的公司
                    </div>
                  </div>
                  <i
                    class="iconfont icon-wenhao-xiangsu"
                    style="font-size: 16px"
                  ></i>
                </el-tooltip>
              </span>
            </el-col>
            <el-button
              type="primary"
              size="small"
              @click="addNew"
              style="margin-right: 28px; float: right"
              v-has="'setting:specialCom:add'"
            >
              <i class="el-icon-plus"></i>添加公司
            </el-button>
          </el-row>
        </el-form>
      </el-row>
      <el-row :gutter="24">
        <el-col>
          <el-pagination
            :hide-on-single-page="pagination.total > 10 ? false : true"
            v-if="pagination.total > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pagination.pageSize"
            :pager-count="5"
            layout="total,prev, pager, next,sizes"
            :total="pagination.total"
            style="margin-bottom: 10px"
          ></el-pagination>
        </el-col>
        <el-col :span="24">
          <el-table
            :data="ListData"
            class="tabBorder custor"
            :header-row-style="{ height: '36px', margin: '0' }"
            :header-cell-style="{
              'background-color': '#f5f7fa',
            }"
            v-loading="listLoading"
          >
            <el-table-column
              :show-overflow-tooltip="true"
              prop="name"
              label="特殊公司名称"
              align="left"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="creatorUserName"
              label="创建人"
              align="left"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="creationTime"
              label="创建时间"
              align="left"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop
              label="操作"
              align="left"
              width="150px"
            >
              <template slot-scope="scope">
                <span
                  @click="handleEdit(scope.row.id)"
                  v-has="'setting:specialCom:edit'"
                  class="tabHref"
                  >编辑</span
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :hide-on-single-page="pagination.total > 10 ? false : true"
            v-if="pagination.total > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pagination.pageSize"
            :pager-count="5"
            layout="total,prev, pager, next,sizes"
            :total="pagination.total"
          ></el-pagination>
        </el-col>
      </el-row>
    </div>

    <el-dialog
      :title="titName"
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      append-to-body
      :close-on-click-modal="false"
      width="40%"
      :before-close="handleClose"
    >
      <el-form
        @submit.native.prevent
        :model="createDate"
        ref="createDate"
        :rules="rules"
        label-width="135px"
      >
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="特殊公司名称：" prop="name">
              <el-input
                v-model.trim="createDate.name"
                placeholder="请不要输入超过40个字"
                maxlength="40"
                style="width: 280px"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="default"
          plain
          size="mini"
          @click="
            dialogVisible = false;
            resetForm('createDate');
          "
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :loading="saveloading"
          @click="save('createDate')"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </section>
</template>
<script>
import {
  getPageSpecial,
  addOrEditSpecial,
  getSpecialById,
} from "../../api/api";
export default {
  data() {
    return {
      ListData: [],
      titName: "",
      saveloading: false,
      listLoading: false,
      dialogVisible: false,
      createDate: {
        id: "",
        name: "",
        version: 0,
      },
      filter: {
        name: "",
      },
      rules: {
        name: [
          {
            required: true,
            trigger: "blur",
            message: "请填写公司名称",
          },
        ],
      },
      pagination: {
        total: 0,
        pageNo: 1,
        pageSize: 50,
      },
    };
  },
  methods: {
    addNew() {
      this.titName = "新增";
      this.dialogVisible = true;
      this.createDate.id = "";
    },
    submitForm(formName) {
      this.pagination.pageNo = 1;
      this.loadList();
    },
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveloading = true;
          addOrEditSpecial(this.createDate).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.loadList();
              this.resetForm(formName);
              this.dialogVisible = false;
            }
            this.saveloading = false;
          });
        }
      });
    },
    handleEdit(rid) {
      this.titName = "编辑";
      this.dialogVisible = true;
      this.createDate.id = rid;
      getSpecialById({ id: rid }).then((res) => {
        if (res.success) {
          this.createDate.name = res.result.name;
          this.createDate.version = res.result.version;
        }
      });
    },
    handleClose(done) {
      done();
      this.resetForm("createDate");
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.pagination.pageSize = val;
      this.pagination.pageNo = 1;
      this.loadList();
    },
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      this.pagination.pageNo = val;
      this.loadList();
    },
    loadList() {
      window.scrollTo(0, 0);
      this.listLoading = true;
      getPageSpecial({
        name: this.filter.name,
        current: this.pagination.pageNo,
        size: this.pagination.pageSize,
        searchCount: true,
      }).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.ListData = res.result.records;
          this.pagination.total = res.result.total;
        }
        this.listLoading = false;
      });
    },
  },
  created() {
    this.loadList();
  },
};
</script>
<style lang="scss" scoped>
.box-title {
  padding: 15px 0;
  .tip {
    margin: 0 0 0 15px;
  }
  .tabBorder {
    margin: 0 0 15px 0;
  }
}
.subIco {
  // margin-left: 4px;
  color: #bbbbbb;
  font-size: 14px;
  vertical-align: middle;
  &:hover {
    // color: #ff3f3f;
  }
  .el-icon-question {
    font-size: 16px;
    vertical-align: middle;
  }
}
</style>

